<template>
    <div class="cardStyle m-4 p-4 basic-info">
        <div class="row">
            <div class="col-6">
                <el-avatar :size="140" :src="askOurCounselor.profile_image_url" @error="errorHandler">
                    <img src="/img/dark_blue_man.svg" />
                </el-avatar>
            </div>
            <div class="col-6 text-right">
                <base-button type="dark-blue" outline size="sm" @click="$router.push({name:'admin.ask-our-counselor.edit'})"> <i class="fa fa-pen" ></i> Edit</base-button>
            </div>
            <div class="col-12 mt-2">
                <p class="basic-info-main-heading">BASIC INFO</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Email</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.email}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">First Name</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.first_name}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Last Name</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.last_name}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Title</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.title}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Phone</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.phone}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Street Address</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.address}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Zip Code</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.pin_code}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">City/ State</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.city}}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">Practice/ Hospital Name</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data">{{askOurCounselor.practice || "N/A" }}</p>
            </div>
            <div class="col-6">
                <p class="basic-info-heading">About</p>
            </div>
            <div class="col-6">
                <p class="basic-info-data" v-html="askOurCounselor.about || 'N/A'"></p>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: ['askOurCounselor'],
    components: {
    },
    data() {
        return {}
    },
    methods: {
        errorHandler() {
            return true;
        }
    },
    computed: {

    }
};
</script>
<style scoped lang="scss"></style>
  