<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2 manage-shop-show w-75" v-loading="loaders.fetching">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <div>
                <h1>Ask Our Counselor</h1>
            </div>
        </div>
        <ask-our-counselor v-if="askOurCounselor" :ask-our-counselor="askOurCounselor" />
    </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import AskOurCounselor from "@/views/Components/SuperAdmin/AskOurCounselor/Card";

export default {
    components: {
        BackButton,
        AskOurCounselor
    },
    data() {
        return {
            loaders: {
                fetching: false,
            },
            askOurCounselor: null,
        };
    },
    mounted() {
        this.getAskOurCounselor();
    },
    methods: {
        async getAskOurCounselor() {
            this.loaders.fetching = true;
            try {
                let response = await this.$store.dispatch('_getAskOurCounselor');
                let {
                    data: { data },
                } = response;
                if(!data) this.$router.push({name:"admin.ask-our-counselor.edit"})
                this.askOurCounselor = data;
            } catch (error) {
            }
            this.loaders.fetching = false;
        },
    },
};
</script>
<style scoped lang="scss"></style>
  